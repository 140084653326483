//Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future

.p-scrolltop {
    // bottom: 44px;
    opacity: 0.5;
}
.layout-topbar {
    .p-menu {
        padding: 0.25rem 0;
        background-color: var(--surface-card);
        color: var(--text-color-secondary);
        border: 1px solid #dee2e6;
        border-radius: 6px;
        width: 12.5rem;
    }
    .p-menu .p-menuitem-link {
        padding: 0.75rem 1.25rem;
        color: #495057;
        border-radius: 0;
        transition: box-shadow 0.2s;
        user-select: none;
    }
}

.flag-icon {
    .p-dropdown-label {
        font-size: 1rem;
        padding-right: 0;
        display: flex;
    }
    .p-dropdown-trigger-icon {
        font-size: 0.85rem;
        color: #000;
    }
}

// css tabel
.label-bold {
    font-weight: 600;
}
.stt-table {
    min-width: 3.5rem !important;
    width: 3.5rem !important;
    text-align: center !important;
    .p-column-header-content {
        justify-content: center;
    }
}
.action-table,
.action-tree-table {
    min-width: 8rem !important;
    width: 8rem !important;
    text-align: center !important;
    .p-column-header-content {
        justify-content: center;
    }
}

.header-center {
    width: 8rem !important;
    text-align: center !important;
    .p-column-header-content {
        justify-content: center;
    }
}
@mixin block-ellipsis($row) {
    display: -webkit-box;
    -webkit-line-clamp: $row;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.max-line-3 {
    @include block-ellipsis(3);
    span {
        @include block-ellipsis(3);
    }
}
.max-line-2 {
    @include block-ellipsis(2);
}
// table dài tự động xuống dòng
.table-word-break {
    .p-datatable-tbody {
        word-break: break-word !important;
    }
    .p-treetable-tbody {
        word-break: break-word !important;
    }
}
.break-word {
    word-break: break-word !important;
}
.p-chips {
    .p-chips-multiple-container {
        .p-chips-token {
            margin-top: 0.2rem;
        }
    }
}
.p-toolbar {
    padding-top: 0.5rem;
    padding-bottom: 0rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background: var(--surface-card);
    // border-left:none ;
    // border-right:none ;
    // border-radius: 0;
}
.icon-medium {
    font-size: 1.3rem;
}
.textarea-custom {
    overflow-y: scroll !important;
    max-height: 6rem !important;
}
.p-disabled:not(button, .p-steps-item) {
    opacity: 1 !important;
    background-color: var(--surface-c);
}
// bỏ margin scroll table
.p-datatable-scrollable-header-box,
.p-treetable-scrollable-header-box {
    margin-right: 0 !important;
}

.box-disabled {
    font-size: 1rem;
    color: var(--text-color);
    border: 1px solid var(--surface-border);
    padding: 0.75rem 0.75rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 6px;
    opacity: 1 !important;
    background-color: var(--surface-c);
    word-break: break-word !important;
    max-height: 4.7rem;
    overflow:auto;
    min-height: 2.86rem;
}
.p-tooltip {
    word-break: break-word;
    max-width: 100vw;
}
.position-center {
    top: 50%;
    right: 50%;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    position: absolute;
}
.pointer {
    cursor: pointer !important;
}
.cs-divider.p-divider-vertical {
    margin: 0 0.1rem;
    padding: 0;
}

.not-focus:focus {
    box-shadow: none !important;
}
.layout-topbar .not-hover:hover {
    background-color: var(--surface-card) !important;
}
// cs topBarMenuRef
.layout-topbar-button-cs {
    padding: 0 0.5rem;
    display: flex;
    align-items: center;
    &:hover {
        background-color: var(--surface-hover);
    }
    &:focus {
        @include focused();
        box-shadow: 0 0 0 0.2rem #bfdbfe;
    }
}
.button-focus {
    &:focus {
        @include focused();
    }
}
.layout-topbar {
    .layout-topbar-menu {
        .layout-topbar-button {
            margin-left: 0;
        }
    }
}

.layout-footer {
    justify-content: space-between;
}
.p-dialog-mask {
    z-index: 1000 !important;
}

svg {
    // vertical-align: bottom;
    vertical-align: sub;
}
.country-item-value{
    max-width:80vw;
}
.PageProgress{
    z-index: 1003!important;
}
