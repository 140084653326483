.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@mixin dialog-maximized {
    .dialog-maximized {
        -webkit-transition: none !important;
        transition: none !important;
        -webkit-transform: none !important;
        transform: none !important;
        width: 100vw !important;
        max-height: 100%;
        height: 100%;
        max-width: 100vw !important;
        iframe {
            width: 100% !important;
            height: 100vh !important;
        }
    }
}

.dialog-default {
    position: relative;
    width: auto;
    margin: 0.5rem;
}
@media screen and (min-width: $xs) {
    .layout-content {
        padding-top: var(--height-header-footer) !important;
    }
    .dialog-xs {
        max-width: 300px !important;
    }
    @include dialog-maximized();
}
@media screen and (min-width: $sm) {
    .container {
        max-width: 540px;
    }
    .dialog-default {
        max-width: 500px !important;
        margin: 1.75rem auto;
    }
    .dialog-sm {
        max-width: 300px !important;
    }
    @include dialog-maximized();
}
// tablet
@media screen and (min-width: $md) {
    .container {
        max-width: 720px;
    }
    .dialog-md {
        max-width: 500px !important;
    }
    @include dialog-maximized();
}
// laptop
@media screen and (min-width: $lg) {
    .container {
        max-width: 960px;
    }
    .dialog-lg,
    .dialog-xl {
        max-width: 800px !important;
    }
    @include dialog-maximized();
}
@media screen and (min-width: $xl) {
    .container {
        max-width: 1140px;
    }
    .dialog-xl {
        max-width: 1140px !important;
    }
    @include dialog-maximized();
}

// responsive table
@media screen and (max-width: $md) {
    html {
        font-size: 13px;
    }
    .p-paginator {
        .p-dropdown,
        .p-inputwrapper,
        .p-inputwrapper-filled {
            margin-left: 0 !important;
            margin-bottom: 0.5rem !important;
        }
        button {
            margin-bottom: 0.5rem !important;
        }
    }
    .p-toolbar-group-left {
        width: 100% !important;
    }
    .p-toolbar-group-right {
        width: 100% !important;
    }
    .p-paginator {
        button {
            min-width: 2rem !important;
            height: 2rem !important;
        }
        .p-dropdown {
            height: 2rem !important;
        }
    }
    .p-toast {
        width: 20rem;
        right: 0 !important;
    }
    .p-datatable {
        &.p-datatable-responsive-demo {
            .stt-table {
                width: 100% !important;
                text-align: left !important;
            }
            .p-datatable-tbody > tr.p-highlight {
                background-color: var(--surface-atbody) !important;
                color: var(--text-color);
            }
            .p-column-title,
            .flex .p-column-title {
                width: 50% !important;
                min-width: 50% !important;
                margin-right: 0 !important;
            }
            tr > td {
                border-width: 1px;
            }
            td {
                border-bottom: solid 1px var(--surface-d) !important;
            }
            .checkbox-table {
                &::before {
                    content: "Chọn ";
                    font-weight: bold;
                    width: 50% !important;
                    display: inline-block;
                    margin: -0.4em 0 -0.4em -0.4rem;
                }
            }
            .action-table {
                width: 100% !important;
                text-align: left !important;
            }
            tr {
                border-left: solid 1px var(--surface-d) !important;
                border-right: solid 1px var(--surface-d) !important;
                &:first-child {
                    border-top: solid 1px var(--surface-d) !important;
                }
                &:nth-child(odd) {
                    background: var(--surface-c);
                }
            }
        }
    }
    @mixin block-ellipsis($row) {
        display: -webkit-box;
        -webkit-line-clamp: $row;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .max-line-3 {
        @include block-ellipsis(5);
        span {
            @include block-ellipsis(5);
        }
    }
    .p-toast {
        width: 20rem;
        right: 0 !important;
    }
    .home {
        .p-card-body {
            .p-card-content {
                font-size: xx-large !important;
            }
        }
    }
    .login-wrap {
        .login-html {
            min-width: 95vw !important;
            min-height: 95vh !important;
            // padding: 8.5rem 3rem;
        }
        .img-logo {
            width: 15rem !important;
        }
    }
    .layout-footer {
        padding: 0.5rem 2rem;
    }
}
@media screen and (max-width: $md) {
    .layout-news .layout-news-container img.layouts-news-mockup-image {
        width: 95%;
    }
    .p-button:not(.p-button-icon-only) {
        display: flex;
        width: 100%!important;
    }
    .btn-icon-max{
        width: 100%!important;
    }
    .p-splitbutton{
        display: flex;
        width: 100%!important;
    }
    .button-cs {
        margin-top: 0.5rem!important;
    }

}
