.text-black {
    color: #000;
}
.text-white {
    color: var(--white);
}

.text-orange-500 {
    color: var(--orange-500);
}
.text-normal {
    font-weight: normal;
    padding: 0;
}

.bg-surface-c {
    background-color: var(--surface-c);
}
.bg-surface-d {
    background-color: var(--surface-d);
}
.bg-default {
    background-color: var(--color-background-gray);
}
.text-danger {
    color: var(--danger);
}
.text-success {
    color: var(--success);
}
.text-primary {
    color: var(--primary);
}
.text-warning {
    color: var(--warning);
}
.text-secondary {
    color: var(--secondary);
}
.text-info {
    color: var(--info);
}
.text-default {
    color: var(--text-color);
}

.bg-danger {
    background-color: var(--danger);
}
.bg-success {
    background-color: var(--success);
}
.bg-primary {
    background-color: var(--primary);
}
.bg-warning {
    background-color: var(--warning);
}
.bg-secondary {
    background-color: var(--secondary);
}
.bg-info {
    background-color: var(--info);
}
.bg-default {
    background-color: var(--background-app);
}
