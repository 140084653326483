@mixin p-display($sc) {
    @each $d in $display {
        .p-d-#{$sc}-#{$d} {
            display: $d !important;
        }
    }
}
@mixin p-width-auto($sc) {
    .w-#{$sc}-auto {
        width: auto !important;
    }
}
@mixin p-margin-0($sc) {
    .w-#{$sc}-auto {
        width: auto !important;
    }
}
@mixin overflow($sc) {
    @each $OV in $overFlow {
        .overflow-#{$sc}-#{$OV} {
            overflow: $OV !important;
        }
        .overflow-x-#{$sc}-#{$OV} {
            overflow-x: $OV !important;
        }
        .overflow-y-#{$sc}-#{$OV} {
            overflow-y: $OV !important;
        }
    }
}

@mixin p-width($sc) {
    @each $w in $width {
        .w-#{$sc}-#{$w} {
            width: $w * 1% !important;
        }
        .h-#{$sc}-#{$w} {
            height: $w * 1% !important;
        }
        .vw-#{$sc}-#{$w} {
            width: $w * 1vw !important;
        }
        .vh-#{$sc}-#{$w} {
            height: $w * 1vh !important;
        }
        .wr-#{$sc}-#{$w} {
            width: $w * 1rem !important;
        }
        .hr-#{$sc}-#{$w} {
            height: $w * 1rem !important;
        }
        .wp-#{$sc}-#{$w} {
            width: $w * 1px !important;
        }
        .hp-#{$sc}-#{$w} {
            height: $w * 1px !important;
        }
        .we-#{$sc}-#{$w} {
            width: $w * 1em !important;
        }
        .he-#{$sc}-#{$w} {
            height: $w * 1em !important;
        }
        .max-w-#{$sc}-#{$w} {
            max-width: $w * 1% !important;
        }
        .max-h-#{$sc}-#{$w} {
            max-height: $w * 1% !important;
        }
        .max-vw-#{$sc}-#{$w} {
            max-width: $w * 1vw !important;
        }
        .max-vh-#{$sc}-#{$w} {
            max-height: $w * 1vh !important;
        }
        .max-wr-#{$sc}-#{$w} {
            max-width: $w * 1rem !important;
        }
        .max-hr-#{$sc}-#{$w} {
            max-height: $w * 1rem !important;
        }
        .max-wp-#{$sc}-#{$w} {
            max-width: $w * 1px !important;
        }
        .max-hp-#{$sc}-#{$w} {
            max-height: $w * 1px !important;
        }
        .max-we-#{$sc}-#{$w} {
            max-width: $w * 1em !important;
        }
        .max-he-#{$sc}-#{$w} {
            max-height: $w * 1em !important;
        }

        .min-w-#{$sc}-#{$w} {
            min-width: $w * 1% !important;
        }
        .min-h-#{$sc}-#{$w} {
            min-height: $w * 1% !important;
        }
        .min-vw-#{$sc}-#{$w} {
            min-width: $w * 1vw !important;
        }
        .min-vh-#{$sc}-#{$w} {
            min-height: $w * 1vh !important;
        }
        .min-wr-#{$sc}-#{$w} {
            min-width: $w * 1rem !important;
        }
        .min-hr-#{$sc}-#{$w} {
            min-height: $w * 1rem !important;
        }
        .min-wp-#{$sc}-#{$w} {
            min-width: $w * 1px !important;
        }
        .min-hp-#{$sc}-#{$w} {
            min-height: $w * 1px !important;
        }
        .min-we-#{$sc}-#{$w} {
            min-width: $w * 1em !important;
        }
        .min-he-#{$sc}-#{$w} {
            min-height: $w * 1em !important;
        }
    }
}
@mixin align-text($sc) {
    @each $al in $align {
        .p-#{$sc}-text-#{$al} {
            text-align: $al !important;
        }
    }
}

.select-option-custom {
    max-width: 15rem !important;
}

@mixin dropdown-rs($width) {
    .dropdown-responsive {
        .select-option-custom {
            max-width: $width !important;
        }
    }
}

@include p-display("");
@include p-width("");
@include p-width-auto("");

@media screen and (min-width: $xs) {
    @include p-display("xs");
    @include p-width("xs");
    @include p-width-auto("xs");
    @include align-text("xs");
    @include overflow("xs");
}
@media screen and (min-width: $sm) {
    @include p-display("sm");
    @include p-width("sm");
    @include p-width-auto("sm");
    @include align-text("sm");
    @include overflow("sm");
}
@media screen and (min-width: $md) {
    @include p-display("md");
    @include p-width("md");
    @include p-width-auto("md");
    @include align-text("md");
    @include overflow("md");
    @include dropdown-rs($sm);
}

@media screen and (min-width: $lg) {
    @include p-display("lg");
    @include p-width("lg");
    @include p-width-auto("lg");
    @include align-text("lg");
    @include overflow("lg");
    @include overflow("lg");
    @include dropdown-rs($md);
}
@media screen and (min-width: $xl) {
    @include p-display("xl");
    @include p-width("xl");
    @include p-width-auto("xl");
    @include align-text("xl");
    @include overflow("xl");
    @include dropdown-rs($lg);
}
@media print {
    @include p-display("print");
    @include p-width("print");
    @include p-width-auto("print");
    @include align-text("print");
    @include overflow("print");
}
@each $fw in $fontWeight {
    .text-fw-#{$fw} {
        font-weight: $fw;
    }
}
@each $fs in $width {
    .font-size-#{$fs} {
        font-size: $fs * 1px;
    }
}
@each $ver in $vertical {
    .align-#{$ver} {
        vertical-align: $ver !important;
    }
}
.wr--13-1 {
    width: 13.1rem;
}
