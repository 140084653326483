.Loader {
    z-index: 1002;
    border: none;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgb(0, 0, 0);
    opacity: 0.6;
    cursor: wait;
    position: fixed;
}
